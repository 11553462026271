<template lang='pug'>
  p 123
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>
